<template>
  <section class="self_sec">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="sso_message">
            <h3>
              If your company uses Single Sign On (SSO) for ergo access, please make sure to log in that way!
            </h3>
          </div>
          <div class="login_signup_form pb_60 pt_10 mt_10" v-if="!verifyForm">
            <!-- <FlashMessage :error="error" v-if="checkType" class="text-center" /> -->
            <h2 class="text_black font_size_36 mb_40">Login</h2>
            <form @submit.prevent="login" v-if="showForm" data-vv-scope="loginForm">
              <div class="form-group mb_50">
                <label for="login_email" class="font_size_24 mb_20 font_bold">Email</label>
                <input id="login_email" type="text" name="email" placeholder="Email" class="form-control" v-model="email"
                  v-validate="'required|email'" />
                <small class="error text-danger" v-show="errors.has('loginForm.email')">
                  {{ errors.first("loginForm.email") }}
                </small>
              </div>
              <div class="form-group mb_50">
                <label for="login_pwd" class="font_size_24 mb_20 font_bold">Password</label>
                <div class="password-container">
                <input name="Password" id="login_pwd"  :type="showPassword ? 'text' : 'password'" placeholder="Password" class="form-control password-input"
                  v-model="password" v-validate="'required'" onpaste="return false;" oncopy="return false;"
                  ondrop="return false;">
                  <span
                  :class="{'toggle-password-icon': true, 'erroronpwd': errors.has('loginForm.Password')}"
                    @mouseover="showPassword = true"
                    @mouseleave="showPassword = false"
                  >
                  <img :src="showPassword ? require('../assets/images/password-show.png') : require('../assets/images/password-hide.png')"  alt="Toggle Password Visibility" class="icon"  />
                  </span>
                <small class="error text-danger" v-show="errors.has('loginForm.Password')">
                  {{ errors.first("loginForm.Password") }}
                </small>
              </div>
              </div>
              <div class="d-sm-flex justify-content-sm-between">
                <div class="form_check_box d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" value="" aria-label="..." v-model="remember_me"
                    id="flexCheckDefault" />
                  <label class="font_size_24 font_bold text_balticsea" for="flexCheckDefault">Remember Me</label>
                </div>
                <div class="forgotpassword">
                  <router-link :to="{ name: 'forgotPassword' }" class="text_primary font_size_16">Forgot
                    Password?</router-link>
                </div>
              </div>
              <div class="
                  mt_68
                  d-flex
                  justify-content-center
                  align-items-center
                  btn_link_box
                ">
                <div class="">
                  <button class="btn btn_primary">Login</button>
                  <!-- <router-link
                    :to="{ name: 'password_encryption' }"
                    class="btn btn_primary"
                    >Login</router-link
                  > -->
                </div>
                <!-- <div class="accout_box">
                  <p class="m-0">
                    Don't have an account?
                    <router-link :to="{ name: 'register' }"
                      ><span>Register</span></router-link
                    >
                  </p>
                </div> -->
              </div>
            </form>
            <div v-else>
              Too many attempts, please try to login after 1 minute.
            </div>
          </div>
          <div class="login_signup_form py_60 mt_10" v-else>
            <p class="text_concord font_size_22 mb_20">
              Please enter the 6 digit code from your work email.
            </p>
            <form @submit.prevent="verifyCode" data-vv-scope="codeForm">
              <div class="form-group">
                <label class="font_size_24 mb_20 font_bold">Security Code</label>
                <input id="code" name="code" key="code-input" type="password" placeholder="Security Code"
                  class="mb_20 form-control" v-model="code" v-validate="'required'" />
                <p class="error text-danger font_size_18 mb-4" v-show="errors.has('codeForm.code')">
                  {{ errors.first("codeForm.code") }}
                </p>
              </div>
              <div class="form_check_box d-flex align-items-center">
                <input class="form-check-input ms-0" type="checkbox" value="" aria-label="..." v-model="remember_code"
                  id="flexCheckDefaultCode" />
                <label class="font_size_24 font_bold text_balticsea" for="flexCheckDefaultCode">Remember This Computer</label>
              </div>
              <div class="mt_68 d-flex justify-content-between align-items-center btn_link_box">
                <div>
                  <button class="btn btn_primary">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 p-0">
          <div class="self_img selg_log">
            <img src="../assets/images/schedule_img.png" alt="img" />
          </div>
        </div> -->
    </div>
  </section>
</template>

<script>
import { getError } from "@/utils/helpers";
// import FlashMessage from "@/components/FlashMessage";
import AuthService from "@/services/AuthService";
import commonFunction from "../mixin/commonFunction";

// import store from "@/store/index";

export default {
  name: "LoginForm",
  // components: {
  //   FlashMessage,
  // },
  mixins: [commonFunction],
  data() {
    return {
      email: null,
      password: null,
      error: null,
      remember_me: false,
      checkType: false,
      is_sso: false,
      fist_name: null,
      last_name: null,
      showForm: true,
      verifyForm: false,
      code: null,
      enc_user_id: null,
      remember_code: false,
      showPassword: false,
    };
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    window.localStorage.removeItem("user_profile");
    window.localStorage.removeItem("id_token_client");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("companyInformation");
    window.localStorage.removeItem("loginType");
    window.localStorage.removeItem("employeeEmail");

    if (this.$route.params.error) {
      this.checkType = true;
      this.error = this.$route.params.error;
      this.$toast.error(this.$route.params.error, {
        position: "top-right",
        duration: 5000,
      });
    }

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.first_name = this.$route.query.first_name;
      this.last_name = this.$route.query.last_name;
      this.is_sso = true;
      this.login();
    } else {
      this.$router.push({
        name: "login"
      }).catch(() => true);
    }
    document.body.classList.add('login')
    this.$parent.$refs.fullpageloader.is_full_page_loader = false;

    //  if(window.localStorage.getItem("loginAttempt") == 3){
    //   this.showForm = false;
    // }

  },
  methods: {
    async login() {
      await this.$validator.validateAll("loginForm").then((isValid) => {
        if (isValid) {
          this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          const payload = {
            email: this.email,
            password: this.password,
            remember_me: this.remember_me,
            is_sso: this.is_sso,
            first_name: this.first_name,
            last_name: this.last_name,
          };

          this.error = null;
          this.checkType = false;
          AuthService.login({ request_data: this.encodeAPIRequest(payload) }).then((response) => {
            var decodedData = this.decodeAPIResponse(response.data.data);
            if (decodedData.first_time_login && decodedData.first_time_login == true) {
              this.$router.push({ name: 'resetPassword', query: { token: decodedData.token } });
            } else {
              if (decodedData.verify == false) {
                this.verifyForm = true;
                this.enc_user_id = decodedData.enc_user_id
                this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              } else {
                window.localStorage.setItem("userToken", decodedData.token);
                this.getAuthUser();
              }
            }

          }).catch((error) => {
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            this.$toast.error(error.response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (error.response.data.attempt_count && error.response.data.attempt_count >= 6) {
              this.$router.push({
                name: "forgotPassword"
              }).catch(() => true);
            }
          });
        } else {
          console.log('Error in validation');
        }
      });
    },

    getAuthUser() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      //var _this = this;
      AuthService.getAuthUser()
        .then((response) => {
          if (response) {
            window.localStorage.setItem("employeeEmail", response.data.user.email);
            window.localStorage.setItem("user_profile", response.data.user.avatar);
            window.localStorage.setItem("companyInformation", JSON.stringify(response.data.client));
            window.localStorage.setItem("loginType", response.data.user.login_type);
            if (window.localStorage.getItem('companyInformation')) {
              if (response.data.client.company_code.toLowerCase() == 'airbnb')
                window.location.href = window.location.origin + '/airbnb';
              else
                window.location.href = window.location.origin + '/user';

            }
          }

          this.$parent.$refs.fullpageloader.is_full_page_loader = false;

        })
        .catch((error) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.error = getError(error);
          const errType = Object.prototype.toString
            .call(getError(error))
            .slice(8, -1)
            .toLowerCase();
          if (errType === "string") {
            this.checkType = true;
          }
        });
    },

    verifyCode() {
      this.$validator.validateAll("codeForm").then((isValid) => {
        if (isValid) {
          this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          const payload = {
            code: this.code,
            enc_user_id: this.enc_user_id,
            remember_code: this.remember_code
          };

          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/verify",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(payload) },
          };
          this
            .axios(config)
            .then(({ data }) => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;

              var decodedData = this.decodeAPIResponse(data.data);

              if (decodedData.verify == true) {
                this.$toast.success(data.message, {
                  position: "top-right",
                  duration: 5000,
                });

                if (decodedData.token) {
                  window.localStorage.setItem("userToken", decodedData.token);
                  this.getAuthUser();
                }

              }
              // var decodedJson = _this.decodeAPIResponse(data.data);
              // _this.user_roles = decodedJson.userroles;
            })
            .catch(({ response }) => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
            });
        }
        else {
          console.log(0);
        }
      });
    },
  },
};
</script>